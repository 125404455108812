
import Vue from "vue";
import {mapState, mapActions, mapMutations } from "vuex";
import AlcoholWarningDialog from "../AlcoholWarningDialog.vue";
import TobaccoWarningDialog from "../TobaccoWarningDialog.vue";

export default Vue.extend({
  name: "category-card",

  components: { 
    "alcohol-warning-dialog": AlcoholWarningDialog,
    "tobacco-warning-dialog": TobaccoWarningDialog
  },

  props: {
    loading: {
      required: false,
      default: false,
      type: Boolean
    },

    category: {
      required: true,
      type: Object
    },

    subcateogry: {
      required: true,
      type: Object
    },

    index: {
      required: true,
      type: Number
    },

    elementWidth: {
      required: false,
      default: "100px"
    },

    elementHeight: {
      required: false,
      default: "100px"
    }
  },

  data: () => ({
    alcoholWarningDialog: false as boolean,
    tobaccoWarningDialog: false as boolean,
  }),

  computed:{
    ...mapState("partner",["partnerSettings"]),
    ...mapState("store",["selectedStoreDetails"]),
  },


  methods: {
    ...mapActions("product", ["getItems"]),
    ...mapMutations("product", ["SET_LAST_CAT_SELECTED"]),

    getToUrl(): any {
     
      return {
        name: 'productsPageBySubCategory',
        params: {
          categoryId: this.$route.params["categoryId"],
          categoryName: this.$route.params["categoryName"],
          subcategoryId: this.subcateogry.id,
          subcategoryName: this.subcateogry.name,
          title: this.subcateogry.name,
          itemsCollectionId: this.subcateogry.itemsCollectionId,
          itemsCollectionType: this.subcateogry.itemsCollectionType
        }
      }
    },

    proceed(skipCheck: boolean = false) {

      if(skipCheck == false) {
        if(this.subcateogry.isAlcohol == true) {
          this.alcoholWarningDialog = true;
        }

        else if(this.subcateogry.isTobacco == true) {
          this.tobaccoWarningDialog = true;
        }
      }

      else {
        this.$router.push(this.getToUrl())
      }
    },


    // categories image url
    getImgUrl(item: any): string {
        if(item.isCustomCategory == true)
          return item.image;

        return (this as any).partnerSettings.blobUrl + `subcategories/${(this as any).partnerSettings.partnerId}/${item.image}`;
    }
    
  },

});
