import Subcategory from "./Subcategory";

export default class Category {
    id: number = 0;                           // cat id -- !!!!! For Custom Cat the id is set to 0 !!!!!
    name: string = "";                      // Subcat name
    image: string = "";                     // Image url
    sortingPrio: number = 0;                  // order listing 
    subcatList: Array<Subcategory> = [];     // Nbre of existing items in the subcat
    isCustomCategory: Boolean = false;
    campaignId: number = 0;
    isAlcohol: boolean = false;
    isTobacco: boolean = false;

    
    static fetchCategoryObjectFromJson(category: any, index: number): Category {
        var categoryObject = {} as Category;
        categoryObject.subcatList = [] as Subcategory[];

        categoryObject.id = category.id > 0 ? category.id : index * 1000 ;
        categoryObject.name = category.name;
        categoryObject.image = category.image;
        categoryObject.sortingPrio = category.order;
        categoryObject.isCustomCategory = category.id == 0;
        categoryObject.isAlcohol = category.isAlcohol;
        categoryObject.isTobacco = category.isTobacco;
        categoryObject.campaignId = category.campaignId;

        if(category.subcategories && category.subcategories.length > 0) { 
            category.subcategories.forEach((subcat: any) => {
                var subcatObject = new Subcategory();
                subcatObject = subcatObject.fetchSubcategoryObjectFromJson(subcat);
                categoryObject.subcatList.push(subcatObject);
            })
        }

        return categoryObject;
    }
}