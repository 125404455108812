
import Vue from 'vue'
import UITitle from "@/components/UI/Text/UITitle.vue"
import {  mapState } from 'vuex';
import { EStoreType } from '@/enums/EStoreType';
import _requestHelper from '@/apiManager/_requestHelper';
import ItemsSliderWithTitle from '@/components/Items/ItemsSliderWithTitle.vue';
import Breadcrumbds from '@/components/UI/Breadcrumbs.vue';

export default Vue.extend({
    props: {
        categoryId: {
            required: true
        },
        categoryName: {
            required: true
        },

        campaignId: {
            required: false
        },
    },

    components: {
        "ui-title": UITitle,
        ItemsSliderWithTitle,    
        "breadcrumbds": Breadcrumbds,    
    },

    data : () => ({
        loading: false as boolean,
        listOfSubcategories: [] as any[]
    }),

    computed: {
        ...mapState("store", ["selectedStoreDetails"])
    },

    methods: {

        async getCategoriesList() {
            this.$emit("loadingState", true);
            this.loading = true;

            var isCustomCategory = this.$route.query["c"] == "true" 
                ? true 
                : this.$route.query["c"] == "false"
                    ? false
                    : this.$route.query["c"]
                
            await _requestHelper({
                method: "post",
                url: "items/subcategories-page",
                loaderKey: "",
                data: {
                    branchId: (this as any).selectedStoreDetails.id,
                    isLight: (this as any).selectedStoreDetails.storeType == EStoreType.Convenient,
                    categoryId: this.categoryId,
                    isCustomCategory: isCustomCategory,
                    campaignId: isCustomCategory ? this.categoryId : 0,
                },

                successCallback: (listOfSubcategories: any) => {
                    this.listOfSubcategories = listOfSubcategories;
                }
            });

            this.loading = false;
            this.$emit("loadingState", false);
        },
    },

    async mounted() {
        await this.getCategoriesList();
    }


})
