import { Module } from "vuex";
import { _axios } from "../../apiManager/_requestHelper";
import store, { RootState } from "..";
import Cart from "@/models/Cart";
import Product from "@/models/Product";
import User from "@/models/User";
import { StoreState } from "./storeModule";
import CartPerUser from "@/models/CartPerUser";
import ProductInCart from "@/models/ProductInCart";


export interface CartState {
    listOfCartsPerStore: Array<Cart>;
}


export default <Module<CartState, RootState>> {

    namespaced: true,

    state:{
        listOfCartsPerStore: [{storeId: 0, items: []}] as Array<Cart>
    },

    getters: {

        /**
         * Getter to get the current cart of the store depending on the user
         * @returns Store Cart
         */
        currentSelectedCart(state): Cart | undefined {
            var storeId = ((store as any).state["store"] as StoreState).selectedStoreDetails.id;
            return state.listOfCartsPerStore.find(cart => cart.storeId == storeId);
        },


        /**
         * Getter to get the total amount of the current cart
         * @returns amount
         */
        currentSelectedCartTotalAmount(state, getters, rootState, rootGetters): number {
            var cart = getters.currentSelectedCart
            var total: number = 0

            cart.items.forEach((item: ProductInCart) => {
                total += (item.adjPrice * item.quantity)
            });

            return total;
        },

        /**
         * Getter to get the total amount of DRS Value
         * @returns amount
         */
        currentSelectedCartTotalDRSAmount(state, getters, rootState, rootGetters): number {
            var cart = getters.currentSelectedCart
            var total: number = 0

            cart.items.forEach((item: ProductInCart) => {
                if(typeof item.drsValue == "undefined" || item.drsValue == null)
                    item.drsValue = 0;
                
                total += (item.drsValue * item.quantity)
            });

            return total;
        },

        currentSelectedCartTotalEBRDAmount(state, getters, rootState, rootGetters): number {
            var cart = getters.currentSelectedCart
            var total: number = 0

            cart.items.forEach((item: ProductInCart) => {
                if(typeof item.ebrdValue == "undefined" || item.ebrdValue == null)
                    item.ebrdValue = 0;
                
                total += (item.ebrdValue * item.quantity)
            });

            return total;
        },

        /**
         * Getter to get the total quantity of the current cart
         * @returns amount
         */
        currentSelectedCartTotalQuantity(state, getters, rootState, rootGetters): number {
            var cart = getters.currentSelectedCart
            var total: number = 0

            cart.items.forEach((item: ProductInCart) => {
                total += item.quantity
            });

            return total;
        }
    },

    mutations: {
        ADD_ITEM_TO_CART(state, object: any) {
            var cart = object.cart as Cart;
            var product = object.product as Product
            
            if(cart != null) {

                if(!cart.items){                
                    cart.items = [];
                }

                var itemFound = cart.items.find((item: ProductInCart) => item.id == product.id);
                if(!itemFound) {
                    var productToAdd = {
                        id: product.id,
                        itemId: product.itemId,
                        price: product.price,
                        adjPrice: product.adjPrice,
                        adjPriceValue : product.adjPriceValue,
                        quantity: 1,
                        instruction: "",
                        name: product.name,
                        image: product.image,
                        maxOrder: product.maxOrder,
                        quantityInStock: product.quantityInStock,
                        adjVATValue: product.adjVATValue,
                        unitMeasureCode: product.unitMeasureCode,
                        unitMeasure: product.unitMeasure,
                        isWeightSensitive: product.isWeightSensitive,
                        drsValue: product.drsValue,
                        ebrdValue: product.ebrdValue,
                    } as ProductInCart;
    
                    cart.items.push(productToAdd);                    

                    store.commit("ui/cartSnackbar",
                        {
                          show: true,
                          error: false,
                          message: "New item added to cart",
                          item: productToAdd,
                        },
                        { root: true }
                      );
                }
            }
        },

        INCREASE_ITEM_IN_CART(state, object: any) {
            var cart = object.cart as Cart;
            var product = object.product as Product
            
            if(cart != null) {
                var itemFound = cart.items.find((item: ProductInCart) => item.id == product.id);
                if(itemFound) {
                    if(itemFound.quantity < product.maxOrder) {
                        itemFound.quantity++;
                    }
                }           
    
            }
        },

        DECREASE_ITEM_IN_CART(state, object: any) {
            var cart = object.cart as Cart;
            var product = object.product as Product

            if(cart != null) {
                var itemFound = cart.items.find((item: ProductInCart) => item.id == product.id);
    
                if(itemFound) {
                    if(itemFound.quantity > 1) {
                        itemFound.quantity--;                       
                    }
                    else {
                        cart.items = cart.items.filter((item: ProductInCart) => item.id != product.id);
                    }                  
                }    
            }
        },

        EMPTY_CURRENT_CART(state, cart: Cart) {        
            cart.items = [];
        },

        INITIALIZE_CART(state, storeId: number) {
            state.listOfCartsPerStore.push({items: [], storeId: storeId})
        },
    },

    actions: {

        /**
         * Initialize a store cart for the user if needed         
         * @param storeId 
         */
        initializeCart({state, dispatch, commit, getters, rootGetters }, storeId: number) {
            var user = rootGetters["auth/currentSelectedUser"] as User;
            
            // check if the user has an existing cart for the specific store
            // if not => create one
            var cartFound = state.listOfCartsPerStore.find(x => x.storeId == storeId);
            if(cartFound == null) {
                commit("INITIALIZE_CART", storeId);
            }
        },

        addItemToCart({state, dispatch, commit, getters, rootGetters }, product: Product) {
            var cart = getters.currentSelectedCart as Cart;
            if(typeof cart != "undefined") {
                commit("ADD_ITEM_TO_CART", {cart: cart, product: product});
            }
        },

        increaseItemInCart({state, dispatch, commit, getters, rootGetters }, product: Product) {
            var cart = getters.currentSelectedCart as Cart;
            if(typeof cart != "undefined") {
                commit("INCREASE_ITEM_IN_CART", {cart: cart, product: product});
            }
        },

        decreaseItemInCart({state, dispatch, commit, getters, rootGetters }, product: Product) {
            var cart = getters.currentSelectedCart as Cart;
            if(typeof cart != "undefined") {
                commit("DECREASE_ITEM_IN_CART", {cart: cart, product: product});
            }
        },

        emptyCurrentCart({state, dispatch, commit, getters, rootGetters }) {
            var cart = getters.currentSelectedCart as Cart;
            if(typeof cart != "undefined") {
                commit("EMPTY_CURRENT_CART", cart);
            }
        },
    }
}
